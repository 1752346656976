<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <div style="margin-left:50px">
        <div>积分规则</div>
        <div style="margin: 20px;"></div>
        <el-form
          ref="orderFormRef"
          :rules="formRules"
          label-width="145px"
          :model="formLabelAlign"
        >
          <el-form-item
            label="积分规则"
            prop="ruleType"
          >
            <el-radio
              v-model="formLabelAlign.ruleType"
              :label="label1"
              border
              size="medium"
            >消费金额</el-radio>
            <el-radio
              v-model="formLabelAlign.ruleType"
              :label="label2"
              border
              size="medium"
            >订单数量</el-radio>
          </el-form-item>
          <el-form-item
            label="消费"
            v-if="isShowMoney"
            prop="amount"
          >
            <span>
              <el-input v-model="formLabelAlign.amount"></el-input>
              <span style="margin-left:10px;color:#606266">元</span>
            </span>
          </el-form-item>
          <el-form-item
            label="积分"
            v-if="isShowMoney"
            prop="points"
          >
            <span>
              <el-input v-model="formLabelAlign.points"></el-input>
              <span style="margin-left:10px;color:#606266">分</span>
            </span>
            <div style="color:#999">
              如满1元积1分，不足1元则不积分
            </div>
          </el-form-item>
          <el-form-item
            label="每完成一笔订单积"
            class="order"
            v-if="isShowOrder"
            prop="orderPoints"
          >
            <span>
              <el-input v-model="formLabelAlign.orderPoints"></el-input>
              <span style="margin-left:10px;color:#606266">分</span>
            </span>
          </el-form-item>
          <el-form-item
            label="积分有效期"
            prop="aging"
          >
            <el-radio
              v-model="formLabelAlign.aging"
              :label="label1"
              border
              size="medium"
            >永久有效</el-radio>
            <el-radio
              v-model="formLabelAlign.aging"
              :label="label2"
              border
              size="medium"
            >定期清空</el-radio>
          </el-form-item>
          <el-form-item
            label="每年固定时间清空"
            v-if="isShowValidity"
            prop="clearTime"
          >
            <el-date-picker
              v-model="formLabelAlign.clearTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <span
          slot="footer"
          class="btn"
        >
          <el-button @click="quxiao">取 消</el-button>
          <el-button
            type="primary"
            @click="submit"
          >确 定</el-button>
        </span>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userList: [],
      value1: '',
      labelPosition: 'left',
      formLabelAlign: {
        amount: '',
        points: '',
        orderPoints: '',
        ruleType: 0,
        aging: 0,
        clearTime: ''
      },
      label1: 0,
      label2: 1,
      formRules: {
        ruleType: [
          { required: true, message: '请选择积分规则', trigger: 'blur' }
        ],
        aging: [
          { required: true, message: '请选择积分有效期', trigger: 'blur' }
        ],
        clearTime: [
          { required: true, message: '请选择积分清除时间', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getDetailSys()
  },
  computed: {
    isShowOrder () {
      return this.formLabelAlign.ruleType === 1
    },
    isShowMoney () {
      return this.formLabelAlign.ruleType === 0
    },
    isShowValidity () {
      return this.formLabelAlign.aging === 1
    }
  },
  methods: {
    async getDetailSys () {
      const { data: res } = await this.$http.get('/pointsRule/selectOne')
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      if (res.data !== null) this.formLabelAlign = res.data
      this.formLabelAlign.amount = this.formLabelAlign.amount / 100
      console.log(this.formLabelAlign, 111)
      // console.log(this.formLabelAlign, 111)
    },
    async submit () {
      this.formLabelAlign.amount = this.formLabelAlign.amount * 100
      this.$refs.orderFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/pointsRule',
          this.formLabelAlign
        )
        if (res.code !== 0) {
          return this.$message.error(res.message)
        }
        this.$message.success('添加成功')
        this.getDetailSys()
      })
    },
    quxiao () {
      this.getDetailSys()
    }
  }
}
</script>

<style lang="less" scoped>
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
}
.el-input {
  width: 250px;
}
.btn {
  margin-left: 200px;
}
// .order {
//   margin-top: -165px;
// }
</style>
